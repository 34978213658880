<template>
  <SpotFulfillmentCard />
</template>

<script>
import SpotFulfillmentCard from '@/components/SpotFulfillmentCard';

export default {
  components: {
    SpotFulfillmentCard
  }
};
</script>
